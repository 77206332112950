<template>
  <div id="accordion">
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserverOption"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="texto"
              rules="required|max:250"
            >
              <b-form-group
                :label="$t('DataAccess.ConditionFiltersOptions.columns.text')"
              >
                <b-form-input
                  v-model="conditionFilterOption.texto"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-checkbox
                id="presetValue"
                v-model="conditionFilterOption.preseleccionado"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t("DataAccess.ConditionFiltersOptions.columns.presetValue") }}
              </b-form-checkbox>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="valor"
              rules="required|max:1000"
            >
              <b-form-group
                :label="$t('DataAccess.ConditionFiltersOptions.columns.value')"
              >
                <b-form-input
                  v-if="!multipleValues"
                  v-model="conditionFilterOption.valor"
                  :state="getValidationState(validationContext)"
                />
                <v-select
                  v-if="multipleValues"
                  v-model="conditionFilterOption.valor"
                  taggable
                  :multiple="true"
                  :clearable="true"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-checkbox
                id="multipleValuesId"
                v-model="multipleValues"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t("DataAccess.ConditionFiltersOptions.columns.useMultipleValues") }}
              </b-form-checkbox>
            </validation-provider>
          </b-col>
        </b-row>
        <div class="float-right mt-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >
            {{ conditionFilterOption.condicionFiltradoOpcionId==0 ? $t('Lists.Add') : $t('Lists.Edit') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
  max,
} from '@validations'
import vSelect from 'vue-select'
import generalDataService from '@/services/generalData.service'
import { ref } from '@vue/composition-api'

export default {
  components: {
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    vSelect,
  },
  props: {
    id: {
      type: Number,
      default: 0,
      required: false,
    },
    condicionFiltradoId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  setup(props, { emit }) {
    const multipleValues = ref(false)
    const blankConditionFilterOption = {
      condicionFiltradoOpcionId: 0,
      condicionFiltradoId: 0,
      texto: '',
      valor: '',
      orden: 1,
      preseleccionado: false,
      archivado: false,
      condicionFiltradoOpcionPadreId: null,
    }
    const {
      fetchConditionFilterOption,
      createConditionFilterOption,
      updateConditionFilterOption,
    } = generalDataService()

    const conditionFilterOption = ref(JSON.parse(JSON.stringify(blankConditionFilterOption)))
    conditionFilterOption.value.condicionFiltradoId = props.condicionFiltradoId

    const resetConditionFilterOptionData = () => {
      conditionFilterOption.value = JSON.parse(JSON.stringify(blankConditionFilterOption))
    }

    if (props.id !== 0) {
      fetchConditionFilterOption(props.id, data => {
        conditionFilterOption.value = data
        if (conditionFilterOption.value.valor.includes(',')) {
          multipleValues.value = true
          const values = conditionFilterOption.value.valor.split(',')
          conditionFilterOption.value.valor = values
        }
      })
    }

    const onSubmit = () => {
      if (multipleValues.value) {
        conditionFilterOption.value.valor = conditionFilterOption.value.valor.toString()
      }
      if (conditionFilterOption.value.condicionFiltradoOpcionId === 0) {
        createConditionFilterOption(conditionFilterOption.value, () => {
          emit('fetch-records')
        })
      } else {
        updateConditionFilterOption(conditionFilterOption.value.condicionFiltradoOpcionId, conditionFilterOption.value, () => {
          emit('fetch-records')
        })
      }
    }
    const {
      refFormObserverOption,
      getValidationState,
      resetForm,
    } = formValidation(resetConditionFilterOptionData)

    return {
      // Data
      conditionFilterOption,
      multipleValues,
      // Validation
      max,
      required,
      getValidationState,
      resetForm,
      refFormObserverOption,
      // Methods
      onSubmit,
    }
  },
}
</script>
