<template>
  <div>
    <h3 class="mb-2">
      {{ $t('Routes.presetValues') }}
      <b-button
        v-b-modal="'add-edit-option-filter-modal'"
        size="sm"
        variant="success"
        @click="selectedOption=0"
      >
        <feather-icon
          icon="PlusIcon"
          size="16"
          class="align-middle"
        />
      </b-button>
    </h3>
    <b-row class="mb-1">
      <b-col
        cols="12"
        md="4"
      >
        <b-form-checkbox
          id="multipleValuesOption"
          v-model="condicionFiltrado.permitirMultiseleccion"
          :value="true"
          :unchecked-value="false"
        >
          <strong> {{ $t("DataAccess.ConditionFilters.columns.multipleChoice") }} </strong>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-checkbox
          v-if="!condicionFiltrado.permitirMultiseleccion"
          id="todoOption"
          v-model="condicionFiltrado.incluirOpcionTodos"
          :value="true"
          :unchecked-value="false"
        >
          <strong> {{ $t("DataAccess.ConditionFilters.columns.todoOption") }} </strong>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <draggable
      v-model="conditionFilterOptions"
      class="list-group list-group-flush cursor-move"
      tag="ul"
    >
      <transition-group
        type="transition"
        name="flip-list"
      >
        <b-list-group-item
          v-for="(conditionFilterOption, index) in conditionFilterOptions"
          :key="`${index}_conditionFilterOption`"
          tag="li"
        >
          <b-row>
            <b-col
              cols="4"
            >
              <label> {{ $t('DataAccess.ConditionFiltersOptions.columns.text') }}: </label>
              <strong> {{ conditionFilterOption.texto }} </strong>
            </b-col>
            <b-col
              cols="4"
            >
              <label> {{ $t('DataAccess.ConditionFiltersOptions.columns.value') }}: </label>
              <strong> {{ conditionFilterOption.valor }} </strong>
            </b-col>
            <b-col
              cols="2"
            >
              <b-form-group>
                <feather-icon
                  icon="ChevronsUpIcon"
                  size="16"
                  class="align-middle"
                />
                <feather-icon
                  icon="ChevronsDownIcon"
                  size="16"
                  class="align-middle"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
            >
              <b-button-group>
                <b-button
                  v-b-modal="'add-edit-option-filter-modal'"
                  variant="info"
                  option="conditionFilterOption"
                  @click="selectedOption = conditionFilterOption.condicionFiltradoOpcionId"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle"
                  />
                </b-button>
                <b-button
                  variant="danger"
                  @click="deleteOption(index, conditionFilterOption)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle"
                  />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-list-group-item>
      </transition-group>
    </draggable>
    <b-modal
      id="add-edit-option-filter-modal"
      ref="modalOptionFilter"
      hide-footer
    >
      <condition-filter-option-add-edit
        :id="selectedOption"
        :condicion-filtrado-id="condicionFiltrado.condicionFiltradoId"
        @fetch-records="fetchRecords"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BButtonGroup,
  BFormGroup,
  BModal,
  BListGroupItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import generalDataService from '@/services/generalData.service'
import { ref } from '@vue/composition-api'
import ConditionFilterOptionAddEdit from './ConditionFilterOptionAddEdit.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BFormGroup,
    BModal,
    BListGroupItem,
    BFormCheckbox,
    draggable,
    ConditionFilterOptionAddEdit,
  },
  props: {
    condicionFiltrado: {
      type: Object,
      default: () => ({
        condicionFiltradoId: null,
        condicionTipoId: null,
        objetoConsultaId: null,
        valorObjetoConsultaColumaId: null,
        textoObjetoConsultaColumaId: null,
        columnaTipoDatoId: null,
        operadorLogicoTipoId: null,
        etiqueta: null,
        esObligatoria: false,
        permitirMultiseleccion: false,
        incluirOpcionTodos: false,
      }),
      required: true,
    },
  },
  setup(props) {
    const {
      fetchConditionFilterOptions,
      updateConditionFilterOption,
      deleteConditionFilterOption,
    } = generalDataService()
    const conditionFilterOptions = ref([])
    const selectedOption = ref(0)
    const modalOptionFilter = ref(null)

    fetchConditionFilterOptions({ condicionFiltradoId: props.condicionFiltrado.condicionFiltradoId }, data => {
      conditionFilterOptions.value = data.condicionFiltradoOpciones
    })
    const fetchRecords = () => {
      fetchConditionFilterOptions({ condicionFiltradoId: props.condicionFiltrado.condicionFiltradoId }, data => {
        modalOptionFilter.value.hide()
        conditionFilterOptions.value = data.condicionFiltradoOpciones
      })
    }
    const updateConditionFilterOptions = () => {
      conditionFilterOptions.value.forEach((conditionFilterOption, index) => {
        // eslint-disable-next-line no-param-reassign
        conditionFilterOption.orden = (index + 1)
        updateConditionFilterOption(conditionFilterOption.condicionFiltradoOpcionId, conditionFilterOption, () => {})
      })
    }
    const deleteOption = (index, item) => {
      deleteConditionFilterOption(item.condicionFiltradoOpcionId, () => {
        conditionFilterOptions.value.splice(index, 1)
        fetchRecords()
      })
    }
    // watch(() => props.condicionFiltrado.permitirMultiseleccion, value => {
    //   if (value) {
    //     // eslint-disable-next-line no-param-reassign
    //     props.condicionFiltrado.incluirOpcionTodos = false
    //   }
    // })
    return {
      // Data
      conditionFilterOptions,
      selectedOption,
      modalOptionFilter,
      // Mehtods
      deleteOption,
      fetchRecords,
      updateConditionFilterOptions,
    }
  },
}
</script>
