<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('settings.dashboards.elements.addedit.wizardFinish')"
      :back-button-text="$t('settings.dashboards.elements.addedit.wizardPrevious')"
      :next-button-text="$t('settings.dashboards.elements.addedit.wizardNext')"
      class="mb-3"
      @on-complete="onFormSubmitted"
    >
      <!-- Element general -->
      <tab-content
        :title="$t('settings.dashboards.elements.addedit.wizardSteps.general')"
        :before-change="onValidationConditionFilter"
      >
        <!-- BODY -->
        <validation-observer
          ref="refNewConditionFilter"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="condicionTipoId"
                rules="required"
              >
                <b-form-group
                  :label="$t('DataAccess.ConditionFilters.columns.typeCondition')"
                >
                  <v-select
                    v-model="ConditionFilterData.condicionTipoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :options="conditionType"
                    :reduce="option => option.id"
                    @input="isFromSource"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!--QueryObject field--->
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <!-- Logic operator field -->
              <validation-provider
                #default="validationContext"
                name="operadorLogicoTipoId"
                rules="required"
              >
                <b-form-group
                  :label="$t('DataAccess.ConditionFilters.columns.logicalOperatorType')"
                >
                  <v-select
                    v-model="ConditionFilterData.operadorLogicoTipoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :options="operatorLogicTypes"
                    :reduce="option => option.id"
                    :disabled="ConditionFilterData.condicionTipoId == 6"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <!-- Column Type Fact field- -->
              <validation-provider
                #default="validationContext"
                name="columnaTipoDatoId"
                rules="required"
              >
                <b-form-group
                  :label="$t('DataAccess.ConditionFilters.columns.columnDataType')"
                >
                  <v-select
                    v-model="ConditionFilterData.columnaTipoDatoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :options="columnType"
                    :reduce="option => option.id"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="isFromSourceType"
              cols="12"
              md="4"
            >
              <!--Condition Type field--->
              <validation-provider
                #default="validationContext"
                name="objetoConsultaId"
                rules="required"
              >
                <b-form-group
                  :label="$t('DataAccess.ConditionFilters.columns.queryObject')"
                >
                  <v-select
                    v-model="ConditionFilterData.objetoConsultaId"
                    label="texto"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :options="objectQuery"
                    :reduce="option => option.id"
                    @close="refetchQueryObjectColumns(ConditionFilterData.objetoConsultaId)"
                    @option:selected="cleanOptions"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="isFromSourceType"
              cols="12"
              md="4"
            >
              <!-- Object Query Valor field- -->
              <validation-provider
                #default="validationContext"
                name="valorObjetoConsultaColumaId"
                rules="required"
              >
                <b-form-group
                  :label="$t('DataAccess.ConditionFilters.columns.columnQueryValue')"
                >
                  <v-select
                    v-model="ConditionFilterData.valorObjetoConsultaColumaId"
                    label="columna"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :options="QueryObjectscolumns"
                    :reduce="option => option.objetoConsultaColumnaId"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="isFromSourceType"
              cols="12"
              md="4"
            >
              <!-- Object Query Text field -->
              <validation-provider
                #default="validationContext"
                name="textoObjetoConsultaColumaId"
                rules="required"
              >
                <b-form-group
                  :label="$t('DataAccess.ConditionFilters.columns.columnQueryText')"
                >
                  <v-select
                    v-model="ConditionFilterData.textoObjetoConsultaColumaId"
                    label="columna"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    :options="QueryObjectscolumns"
                    :reduce="option => option.objetoConsultaColumnaId"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <!-- etiqueta Field -->
              <validation-provider
                #default="validationContext"
                name="etiqueta"
                rules="required"
              >
                <b-form-group
                  :label="$t('DataAccess.ConditionFilters.columns.tag')"
                >
                  <b-form-input
                    v-model="ConditionFilterData.etiqueta"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                v-if="ConditionFilterData.condicionTipoId === 6 && (ConditionFilterData.columnaTipoDatoId === 1 || ConditionFilterData.columnaTipoDatoId === 7)"
                label="En parametro de funcion"
              >
                <b-form-checkbox
                  id="multipleValuesOption"
                  v-model="ConditionFilterData.permitirMultiseleccion"
                  :value="true"
                  :unchecked-value="false"
                >
                  <!-- <strong> {{ $t("DataAccess.ConditionFilters.columns.multipleChoice") }} </strong> -->
                  <strong> Concatenar cuando se tenga mas de un valor</strong>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                v-if="ConditionFilterData.condicionTipoId === 4"
                label="Multiselección"
              >
                <b-form-checkbox
                  id="multipleValuesOption"
                  v-model="ConditionFilterData.permitirMultiseleccion"
                  :value="true"
                  :unchecked-value="false"
                >
                  <!-- <strong> {{ $t("DataAccess.ConditionFilters.columns.multipleChoice") }} </strong> -->
                  <strong> Permitir</strong>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content
        v-if="isPermanent"
        :title="$t('DataAccess.ConditionFiltersOptions.definedValues')"
        :before-change="onValidationConditionFilterOption"
      >
        <ConditionFilterOptionList
          ref="conditionFilterOptionList"
          :condicion-filtrado="ConditionFilterData"
        />
      </tab-content>
      <tab-content
        v-if="ConditionFilterData.condicionTipoId == 6"
        :title="$t('DataAccess.ConditionFiltersOptions.userValues')"
        :before-change="onValidationConditionFilterUserValue"
      >
        <validation-observer
          ref="refConditionValues"
        >
          <b-row>
            <!-- Query object field -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('DataAccess.ConditionFiltersOptions.userSessionValues')"
                rules="required"
              >
                <b-form-group
                  class="mt-1"
                  :label="$t('DataAccess.ConditionFiltersOptions.userSessionValues')"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="conditionFilterOption.valor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="item => item.id"
                    :options="userSessionOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  required,
} from '@validations'
import { ref, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import generalDataService from '@/services/generalData.service'
import optionService from '@/services/option.service'
import router from '@/router'
import ConditionFilterOptionList from './ConditionFilterOptionList.vue'

export default {
  components: {
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,

    vSelect,
    ConditionFilterOptionList,
    FormWizard,
    TabContent,
  },
  setup() {
    /* Services */
    const {
      fetchConditionFilter,
      createConditionFilter,
      fetchConditionFilterType,
      fetchObjectQuery,
      fetchColumnType,
      fetchLogicOperatorType,
      fetchQueryObjectColumns,
      fetchIsFromSource,
      fetchIsPermanent,
      updateConditionFilter,
      createConditionFilterOption,
      updateConditionFilterOption,
    } = generalDataService()
    const { fetchClaimsTypes } = optionService()
    /* Data */
    const blankConditionFiltersData = {
      condicionFiltradoId: null,
      condicionTipoId: null,
      objetoConsultaId: null,
      valorObjetoConsultaColumaId: null,
      textoObjetoConsultaColumaId: null,
      columnaTipoDatoId: null,
      operadorLogicoTipoId: null,
      etiqueta: null,
      esObligatoria: false,
      permitirMultiseleccion: false,
      incluirOpcionTodos: false,
      condicionFiltradoOpciones: [],
    }
    const conditionFilterOptionList = ref(null)
    const condicionFiltradoOpcion = ref([])
    const isFromSourceType = ref(false)
    const isPermanent = ref(false)
    const QueryObjectscolumns = ref([])
    const refetchQueryObjectColumns = objetoConsultaId => {
      if (objetoConsultaId !== undefined) {
        fetchQueryObjectColumns(data => {
          QueryObjectscolumns.value = data
        }, objetoConsultaId)
      }
    }
    const conditionType = ref([])
    fetchConditionFilterType(data => {
      conditionType.value = data
    })
    const objectQuery = ref([])
    fetchObjectQuery(data => {
      objectQuery.value = data
    })
    const columnType = ref([])
    fetchColumnType(data => {
      columnType.value = data
    })
    const operatorLogicTypes = ref([])
    fetchLogicOperatorType(data => {
      operatorLogicTypes.value = data
    })
    const userSessionOptions = ref([])
    fetchClaimsTypes(data => {
      userSessionOptions.value = data
    })
    const ConditionFilterData = ref({
      condicionFiltradoId: 0,
      condicionTipoId: null,
      objetoConsultaId: null,
      valorObjetoConsultaColumaId: null,
      textoObjetoConsultaColumaId: null,
      columnaTipoDatoId: null,
      operadorLogicoTipoId: 1,
      etiqueta: null,
      esObligatoria: false,
      permitirMultiseleccion: false,
      incluirOpcionTodos: false,
      condicionFiltradoOpciones: [],
    })
    const conditionFilterOption = ref({
      condicionFiltradoOpcionId: 0,
      condicionFiltradoId: 0,
      texto: '',
      valor: '',
      orden: 1,
      preseleccionado: false,
      archivado: false,
      condicionFiltradoOpcionPadreId: null,
    })
    const resetConditionFilterData = () => {
      ConditionFilterData.value = JSON.parse(JSON.stringify(blankConditionFiltersData))
    }
    const refNewConditionFilter = ref(null)
    const refConditionValues = ref(null)

    /* Events */
    const onValidationConditionFilter = () => new Promise((resolve, reject) => {
      refNewConditionFilter.value.validate().then(success => {
        if (success) {
          if (ConditionFilterData.value.condicionFiltradoId === 0) {
            createConditionFilter(ConditionFilterData.value, data => {
              ConditionFilterData.value.condicionFiltradoId = data.condicionFiltradoId
              condicionFiltradoOpcion.value.condicionFiltradoId = data.condicionFiltradoId
              resolve(true)
            })
          } else {
            updateConditionFilter(ConditionFilterData.value, () => {
              resolve(true)
            })
          }
          if (ConditionFilterData.value.condicionFiltradoOpciones.length === 1 && ConditionFilterData.value.condicionTipoId === 6) {
            conditionFilterOption.value = ConditionFilterData.value.condicionFiltradoOpciones.shift()
          }
        } else {
          reject()
        }
      })
    })

    const onValidationConditionFilterOption = () => new Promise(resolve => {
      conditionFilterOptionList.value.updateConditionFilterOptions()
      resolve(true)
      onValidationConditionFilter()
    })

    const onValidationConditionFilterUserValue = () => new Promise((resolve, reject) => {
      refConditionValues.value.validate().then(success => {
        if (success) {
          conditionFilterOption.value.condicionFiltradoId = ConditionFilterData.value.condicionFiltradoId
          conditionFilterOption.value.texto = conditionFilterOption.value.valor
          if (conditionFilterOption.value.condicionFiltradoOpcionId === 0) {
            createConditionFilterOption(conditionFilterOption.value, () => {
              resolve(true)
            })
          } else {
            updateConditionFilterOption(conditionFilterOption.value.condicionFiltradoOpcionId, conditionFilterOption.value, () => {
              resolve(true)
            })
          }
        } else {
          reject()
        }
      })
    })

    const onFormSubmitted = () => {
      router.push({ name: 'settings-dataaccess-conditionfilters' })
    }

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetConditionFilterData)

    const isPermanentCondition = () => {
      fetchIsPermanent(ConditionFilterData.value.condicionTipoId, data => {
        isPermanent.value = data
      })
    }

    const isFromSource = () => {
      fetchIsFromSource(ConditionFilterData.value.condicionTipoId, data => {
        isFromSourceType.value = data
        isPermanentCondition()
      })
    }

    if (Object.keys(router.currentRoute.params).length !== 0) {
      fetchConditionFilter(router.currentRoute.params, data => {
        ConditionFilterData.value = data
        refetchQueryObjectColumns(ConditionFilterData.value.objetoConsultaId)
        isFromSource()
        isPermanentCondition()
      })
    }

    const cleanOptions = () => {
      ConditionFilterData.value.textoObjetoConsultaColumaId = null
      ConditionFilterData.value.valorObjetoConsultaColumaId = null
    }

    watch(() => ConditionFilterData.value.condicionTipoId, () => {
      if (ConditionFilterData.value.condicionTipoId === 6) {
        ConditionFilterData.value.operadorLogicoTipoId = 7
      }
    })

    return {
      // Data
      ConditionFilterData,
      conditionType,
      objectQuery,
      columnType,
      operatorLogicTypes,
      QueryObjectscolumns,
      userSessionOptions,
      isFromSourceType,
      isPermanent,
      condicionFiltradoOpcion,
      conditionFilterOptionList,
      conditionFilterOption,
      refetchQueryObjectColumns,
      // Events
      onFormSubmitted,
      onValidationConditionFilter,
      onValidationConditionFilterOption,
      onValidationConditionFilterUserValue,
      isFromSource,
      cleanOptions,
      // Validations
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      refNewConditionFilter,
      refConditionValues,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
hr.solid {
  border-top: 3px solid #bbb;
}
</style>
